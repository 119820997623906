<template>
  <div class="w-sm-100 w-xs-100 vs-con-loading__container" id="withdraw-wrapper">
    <vs-row class="lg-trading-content">
      <div class="pt-1 pl-1 text-medium">Select a bank account to withdraw to :</div>

      <vs-row vs-type="flex" class="mb-1 p-1 w-100">


        <div v-for="item in selfBankDetails"
             :key="item.receiver_key"
             class="m-1 trading-bank-account-container trading-flex-space-between pointer"
             :class="{ 'trading-bank-account-container-highlighted ' : selectedBank && (item.receiver_key ===  selectedBank.receiver_key) }"
             @click="selectBank(item)">
          <div>
            <div class="text-medium fw-700">{{ item.bank_name }}</div>
            <div class="text-small">{{ item.bank_acct_num }}</div>
            <div class="text-small text-success">{{ item.bank_acct_ccy }}</div>
          </div>
          <div v-if="selectedBank && (selectedBank.receiver_key === item.receiver_key)">
            <check-icon size="1x" class="text-success ml-2"></check-icon>
          </div>
        </div>

        <!--ADD NEW ACCOUNT BUTTON-->
        <div class="trading-normal-card trading-flex-column text-center p-1 m-1 pointer"
             @click="goToAddBankAccountPage()">
          <div class="text-xlarge">
            +
          </div>
          <div class="text-small">
            Add a new account
          </div>
        </div>
      </vs-row>

      <vs-row vs-type="flex" vs-justify="center">
        <vs-col vs-w="8" vs-xs="12" class="p-1 mb-2">
          <div class="white-card-lg">
            <div style="border-bottom: 1px solid #DDDDDD" class="text-center p-2">
              <div class="text-medium">Amount available</div>
              <div class="text-medium">to withdraw</div>
              <div class="text-xsmall">(Minimum required : SGD > 0)</div>
              <h2 class="mb-4 mt-2 fw-700">SGD {{ formatFiatPrice(fiatBalance) }}</h2>
              <div class="trading-flex-center mb-4">
                <div class="text-small fw-700">
                  Voucher reward SGD {{ formatFiatPrice(voucherBalance) }}
                </div>
                <div class="ml-1 mt-1">
                  <info-icon class=" pointer" @click="showTooltip('#tooltip-text')" size="1x"></info-icon>
                  <div id="tooltip-text" class="trading-tooltip-text text-small fw-600">
                    This amount is only available for crypto purchase.
                  </div>
                </div>
              </div>
            </div>
            <!--            <div class="text-center mt-4 mb-5">-->
            <!--              <h4>SGD</h4>-->
            <!--              <h2>-->
            <!--                {{formatFiatPrice(withdrawalBalance)}}-->
            <!--              </h2>-->
            <!--&lt;!&ndash;              <input type="text" inputmode="decimal" autocomplete="off" placeholder="0"&ndash;&gt;-->
            <!--&lt;!&ndash;                     class="trading-input-amount-md"/>&ndash;&gt;-->
            <!--            </div>-->

            <button class="btn-trading-positive w-100"
                    :disabled="isKYCRequired || !fiatBalance || !wdrw_status"
                    :class="{'btn-disabled' : isKYCRequired || !fiatBalance || !wdrw_status}"
                    @click="clickWithdrawBtn()"
            >Confirm Withdrawal
            </button>
          </div>

          <!--PROCESSING MESSAGE-->
          <div v-if="isProcessing" class="text-medium text-center">
            Processing ....
          </div>

          <div class="text-small mt-1 text-error text-center" v-if="validationBankAccountError">
            Please select a bank account before you do the withdrawal
          </div>

          <br>
          <br>

          <vs-row vs-type="flex" vs-justify="center" v-if="isKYCRequired && kycUrl.length > 0">
            <button class="btn-trading-positive w-100" @click="doKYC()">Verify your identity</button>
            <div class="text-small text-center">You will be redirected to our KYC partner's website for verification.
              Once successful, you will be redirected back to this page.
            </div>
            <p class="mt-2 text-center" v-if="showHelpForKYC">If you have trouble verifying your identity, please submit
              your identity document and a recent photo of yourself or selfie to
              <a href="mailto:support@transcryptglobal.com?subject=Failed%20or%20canceled%20KYC%20onWithdrawToBankAccount">support@transcryptglobal.com</a>.
            </p>
          </vs-row>
          <vs-row>
            <p class="p-1 m-0" :class="{'txAndRcvrStatus': !wdrw_status, 'failTxAndRcvr': wdrw_status}">
              <span class="p-0">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#c73b2d" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"></path>
                </svg>
                Oops! 
                </span>This operation can not be processed. Please contact support us at <strong class="text-success">support@transcryptglobal.com</strong>
            </p>
          </vs-row>
        </vs-col>

<!--        <p>KYC URL : {{kycUrl}}</p>-->
<!--        <p>Is KYC Required : {{isKYCRequired}}</p>-->


        <!--        <vs-col vs-w="7" vs-xs="12" class="p-1 mb-3">-->
        <!--          <vs-row class="white-card-xs mb-2 p-1" vs-type="flex" vs-justify="space-between" vs-align="center">-->
        <!--            <div class="text-medium">Withdrawal history</div>-->
        <!--            <div class="text-medium" @click="seeAllWithdrawals()" v-if="withdrawalsHistory.length > 5">See All</div>-->
        <!--          </vs-row>-->
        <!--          <div v-if="withdrawalsHistory.length > 0">-->
        <!--            <vs-row v-for="item in withdrawalsHistory" class="white-card-md mb-2 p-1" vs-type="flex"-->
        <!--                    vs-justify="space-between" vs-align="center" :key="item.wdrw_ref_num">-->
        <!--              <div class="ml-2 trading-flex">-->
        <!--                <check-icon size="1.5x" class="text-success"></check-icon>-->
        <!--&lt;!&ndash;                <img src="/assets/trading-assets/bank-icon.svg" alt="bank-logo" class="sm-logo"/>&ndash;&gt;-->
        <!--              </div>-->
        <!--              <div class="trading-flex-column text-medium">-->
        <!--                <div class="fw-700">Withdraw to {{ item.bank_name }}</div>-->
        <!--                <div class="text-small">{{ item.bank_acct_num }}</div>-->
        <!--              </div>-->
        <!--              <div class="trading-flex-column text-medium text-right">-->
        <!--                <div class="fw-700">{{ item.wdrw_ccy }} {{ formatFiatPrice(item.wdrw_amt) }}</div>-->
        <!--&lt;!&ndash;                <div class="text-small text-right">completed</div>&ndash;&gt;-->
        <!--              </div>-->

        <!--            </vs-row>-->
        <!--          </div>-->
        <!--          <div v-else>-->
        <!--            <div class="text-medium text-center">-->
        <!--              No withdrawal history-->
        <!--            </div>-->
        <!--          </div>-->

        <!--        </vs-col>-->

        <!--        <vs-popup fullscreen title="Withdrawal History" :active.sync="popupActive">-->
        <!--          <vs-row class="md-trading-content">-->
        <!--            <vs-row class="grey-card-md mb-2 p-1" vs-type="flex" vs-justify="space-between" vs-align="center">-->
        <!--              <div class="ml-2 trading-flex">-->
        <!--                <check-icon size="1.5x" class="text-success"></check-icon>-->
        <!--              </div>-->
        <!--              <div class="trading-flex-column text-medium">-->
        <!--                <div class="fw-700">Withdraw to DBS Bank</div>-->
        <!--                <div class="text-small">275-17454-4</div>-->
        <!--              </div>-->
        <!--              <div class="trading-flex-column text-medium">-->
        <!--                <div class="fw-700">SGD 10.00</div>-->
        <!--                <div class="text-small text-right">completed</div>-->
        <!--              </div>-->

        <!--            </vs-row>-->
        <!--            <vs-row class="grey-card-md mb-2 p-1" vs-type="flex" vs-justify="space-between" vs-align="center">-->
        <!--              <div class="ml-2 trading-flex">-->
        <!--                <check-icon size="1.5x"></check-icon>-->
        <!--              </div>-->
        <!--              <div class="trading-flex-column text-medium">-->
        <!--                <div class="fw-700">Withdraw to DBS Bank</div>-->
        <!--                <div class="text-small">275-17454-4</div>-->
        <!--              </div>-->
        <!--              <div class="trading-flex-column text-medium">-->
        <!--                <div class="fw-700">SGD 10.00</div>-->
        <!--                <div class="text-small text-right">completed</div>-->
        <!--              </div>-->

        <!--            </vs-row>-->
        <!--            <vs-row class="grey-card-md mb-2 p-1" vs-type="flex" vs-justify="space-between" vs-align="center">-->
        <!--              <div class="ml-2 trading-flex">-->
        <!--                <check-icon size="1.5x"></check-icon>-->
        <!--              </div>-->
        <!--              <div class="trading-flex-column text-medium">-->
        <!--                <div class="fw-700">Withdraw to DBS Bank</div>-->
        <!--                <div class="text-small">275-17454-4</div>-->
        <!--              </div>-->
        <!--              <div class="trading-flex-column text-medium">-->
        <!--                <div class="fw-700">SGD 10.00</div>-->
        <!--                <div class="text-small text-right">completed</div>-->
        <!--              </div>-->
        <!--            </vs-row>-->
        <!--            <vs-row class="grey-card-md mb-2 p-1" vs-type="flex" vs-justify="space-between" vs-align="center">-->
        <!--              <div class="ml-2 trading-flex">-->
        <!--                <check-icon size="1.5x"></check-icon>-->
        <!--              </div>-->
        <!--              <div class="trading-flex-column text-medium">-->
        <!--                <div class="fw-700">Withdraw to DBS Bank</div>-->
        <!--                <div class="text-small">275-17454-4</div>-->
        <!--              </div>-->
        <!--              <div class="trading-flex-column text-medium">-->
        <!--                <div class="fw-700">SGD 10.00</div>-->
        <!--                <div class="text-small text-right">completed</div>-->
        <!--              </div>-->
        <!--            </vs-row>-->
        <!--            <vs-row class="grey-card-md mb-2 p-1" vs-type="flex" vs-justify="space-between" vs-align="center">-->
        <!--              <div class="ml-2 trading-flex">-->
        <!--                <check-icon size="1.5x" class="text-success"></check-icon>-->
        <!--              </div>-->
        <!--              <div class="trading-flex-column text-medium">-->
        <!--                <div class="fw-700">Withdraw to DBS Bank</div>-->
        <!--                <div class="text-small">275-17454-4</div>-->
        <!--              </div>-->
        <!--              <div class="trading-flex-column text-medium">-->
        <!--                <div class="fw-700">SGD 10.00</div>-->
        <!--                <div class="text-small text-right">completed</div>-->
        <!--              </div>-->

        <!--            </vs-row>-->
        <!--          </vs-row>-->
        <!--        </vs-popup>-->
      </vs-row>

      <!--If KYC is not done/ pass-->

    </vs-row>

  </div>
</template>
<script>
import {CheckIcon, InfoIcon} from 'vue-feather-icons'
import {mapActions, mapGetters} from 'vuex';


export default {
  name: 'withdrawal-page',
  components: {
    CheckIcon,
    InfoIcon
  },
  data() {
    return {
      mode: process.env.VUE_APP_STAGING_ENV ? null : "production",

      popupActive: false,
      withdrawalHistoryList: [],
      selectedBank: null,

      validationBankAccountError: false,

      showHelpForKYC: false,

      isProcessing: false,

      errorInWithdrawing: false,

      debugConsole: false,

      wdrw_status: true

    }
  },
  watch: {},
  computed: {
    ...mapGetters(["userFiatBalanceGetter", "userVoucherBalanceGetter", "selfRecipientsGetter", "userDetailsGetter", "userTransactionHistoryGetter"]),


    fiatBalance() {
      let fiatBalance = this.userFiatBalanceGetter && this.userFiatBalanceGetter.find(item => item.bal_type === 'fiat')
      return Number(fiatBalance.bal_amt);
    },

    withdrawalBalance() {
      let fiatBalance = this.userFiatBalanceGetter && this.userFiatBalanceGetter.find(item => item.bal_type === 'fiat')
      return Number(fiatBalance.bal_amt);
    },

    voucherBalance() {
      let voucherBalance = this.userVoucherBalanceGetter && this.userVoucherBalanceGetter.find(item => item.bal_type === 'voucher');
      return Number(voucherBalance.bal_amt);
    },

    selfBankDetails() {
      let bankDetails = this.selfRecipientsGetter && this.selfRecipientsGetter;
      return bankDetails
    },

    isKYCRequired() {
      let status = this.userDetailsGetter && this.userDetailsGetter.profile && this.userDetailsGetter.profile.senderKYCStatus;

      if (status !== 'pass') {
        return true;
      } else {
        return false;
      }
    },

    kycUrl() {
      let url = this.userDetailsGetter && this.userDetailsGetter.trading && this.userDetailsGetter.trading.kycUrl;
      return url;
    },

    withdrawalsHistory() {
      let withdrawalsHistory = this.userTransactionHistoryGetter && this.userTransactionHistoryGetter.message && this.userTransactionHistoryGetter.message.data &&
          this.userTransactionHistoryGetter.message.data.filter(item => item.tx_type === 'WITHDRAW');

      return withdrawalsHistory || [];
    }


  },
  metaInfo: {
    title: 'TransCrypt - Transfer Form | Send Money Faster with Cryptocurrency',
  },
  mounted() {
    if (this.debugConsole) console.log("In withdrawal page");
    if (this.debugConsole) console.log("Fiat Balance", this.fiatBalance)
    if (this.debugConsole) console.log("withdrawal Balance", this.withdrawalBalance);
    if (this.debugConsole) console.log("self bank details", this.selfBankDetails);

    //get Authenteq URL
    this.getAuthenteqUrl().then(res => {
     if(this.debugConsole) console.log("Get Authenteq URL in withdraw page",res);
    }).catch(err => {
      console.log("Error in getting Authenteq URL in withdraw page", err.response);
    })



    //check the user balance
    //it should reflect the latest balance available to withdraw. So after withdraw and comes back to this page, the amount should be 0.
    this.getUserBalances().then(res => {
      if (this.debugConsole) console.log("Get user balances in the withdraw balance page", res.data)
    }).catch(err => {
      console.log("Error in getting user balances in withdraw balance page", err.response);
    })

    //get transaction history
    this.getTransactionHistoryAction().then(res => {
      if (this.debugConsole) console.log("Get Transaction History in withdrawal page", res.data)


    }).catch(err => {
      console.log("Error in getting txn history in history page", err);
    })


    //check the user if the status is pass or not
    this.getSenderDetails().then(res => {
      if (this.debugConsole) console.log("Get User Details in withdraw balance page", res.data);


    }).catch(err => {
      console.log("Error in getting user details in withdraw balance page", err.response)
    })


    //if it comes back from Authenteq and still having problem with KYC
    if (window.localStorage.getItem('visited_authenteq') === 'true' && this.isKYCRequired) {
      if (this.debugConsole) console.log('user came back from authenteq without successful KYC, please HELP HIM! :D');
      this.showHelpForKYC = true;
    } else {
      if (this.debugConsole) console.log("no need KYC help");
      this.showHelpForKYC = false;
    }


  },
  methods: {
    ...mapActions(["getTransactionHistoryAction", "withdrawToBankAccountAction", "getSenderDetails", "getUserBalances","getAuthenteqUrl", "authLogout"]),

    clickWithdrawBtn() {
      this.$vs.loading({
        container: '#withdraw-wrapper',
        scale: 0.8,
        color: 'success'
      });
      this.isProcessing = true;
      setTimeout(() => {
        this.withdrawToBankAccount();
      }, 1500);
    },
    goToAddBankAccountPage() {
      this.$router.push('/trading/withdraw/bank-account')
    },

    showTooltip(value) {
      let element = document.querySelector(value);

      if (element) {
        element.style.visibility = 'visible';
        setTimeout(() => {
          element.style.visibility = 'hidden';
        }, 2500);
      }
    },

    seeAllWithdrawals() {
      this.popupActive = true;
    },

    selectBank(item) {

      this.selectedBank = item;
      if (this.debugConsole) console.log("Selected Bank", this.selectedBank);
    },


    withdrawToBankAccount() {
      //validation
      if (!this.selectedBank) {
        this.validationBankAccountError = true;
        this.isProcessing = false;
      } else {
        this.validationBankAccountError = false;
      }

      if (this.validationBankAccountError) {
        // close load without selecting bank item
        this.$vs.loading.close('#withdraw-wrapper > .con-vs-loading');
        return;
      }

      let payload = {};
      payload.wdrw_ccy = 'SGD';
      payload.wdrw_amt = this.fiatBalance;
      payload.rcv_key = this.selectedBank.receiver_key;

      if (this.debugConsole) console.log("Payload of withdrawing to bank acc", payload);

      this.withdrawToBankAccountAction(payload).then(res => {
        if (this.debugConsole) console.log("Result of withdraw to bank account in withdraw page", res);

        this.wdrw_status = this.$store.getters.userDetailsGetter.trading.userWithdrawBankAccountDetail.sender_status;
        this.isProcessing = false;
        this.$vs.loading.close('#withdraw-wrapper > .con-vs-loading');
        this.wdrw_status && this.$router.push('/trading/withdraw/notification');

      }).catch(err => {
        console.log("Error in withdrawing to bank account on withdraw page", err);
        this.isProcessing = false;
        this.errorInWithdrawing = true;
        this.$vs.loading.close('#withdraw-wrapper > .con-vs-loading');
      })


    },

    doKYC() {
      // let kycURL = this.userDetailsGetter && this.userDetailsGetter.trading && this.userDetailsGetter.trading.kycUrl;
      //
      //
      // if (this.kycUrl.length > 0) {
      //   window.localStorage.setItem('visited_authenteq', 'true');
      //
      //   window.top.location.href = this.kycUrl;
      // }

      if (this.debugConsole) console.log("KYC URL", this.kycUrl);
      window.localStorage.setItem('visited_authenteq', 'true');

      window.top.location.href = this.kycUrl;

    }


  },
  beforeDestroy() {
    // logout user if move to another page when getting blocked
    if(!this.wdrw_status) {
      this.authLogout().then(res => {
        if (this.debugConsole) console.log("logout the current logged in user", res.data);

          this.$store.commit("USER_LOGOUT");
          this.$router.push('/trading/user/login');

        }).catch(err => {
          if (this.debugConsole) console.log("Error in logut the current loggedi in user", err);
      })
    }
  }
}

</script>
<style>


</style>